/* eslint-disable indent */
// Gatsby supports TypeScript natively!
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/form";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import {
  FaChevronDown,
  FaChevronRight,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";

let blankState = {
  a: false,
  b: false,
  c: false,
  d: false,
  e: false,
  f: false,
  g: false,
  h: false,
};

let originalState = Object.assign({}, blankState);

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: originalState,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(id) {
    let currentValue = this.state.questions[id];
    let newValue = !currentValue;

    let newState = Object.assign({}, blankState);
    newState[id] = newValue;

    this.setState({ questions: newState });
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact Us" />
        <div className="jumbotron">
          <Container>
            <Row md={2} sm={1} xs={1}>
              <Col
                md={{ span: 8, order: 1 }}
                sm={{ order: 2 }}
                xs={{ order: 2 }}
              >
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <h2>Ready to book?</h2>
                        <p>
                          Fill out the form and we'll respond to any enquiries
                          or booking requests as soon as possible!
                        </p>
                        <ContactForm />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ paddingTop: 32 }}>
                    <Card>
                      <Card.Body>
                        <h2>Frequently asked questions</h2>
                        <span className="font-weight-light">
                          Click on a panel to expand it
                        </span>
                        <Accordion style={{ marginTop: 8 }}>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="0"
                              className="question-box"
                              onClick={() => this.toggle("a")}
                            >
                              <span className="question-header">
                                {" "}
                                {this.state.questions["a"] ? (
                                  <FaChevronDown />
                                ) : (
                                  <FaChevronRight />
                                )}{" "}
                                What time is check in/check out?
                              </span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <p>
                                  Check in for Rhylla Cottage is from 5pm. Check
                                  out is by 10am.
                                </p>
                                <p>
                                  Check in for Rhylla Retreat is from 4pm. Check
                                  out is by 9am.
                                </p>
                                <p>
                                  Early check in/late check out by arrangement.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="1"
                              onClick={() => this.toggle("b")}
                            >
                              <span className="question-header">
                                {this.state.questions["b"] ? (
                                  <FaChevronDown />
                                ) : (
                                  <FaChevronRight />
                                )}{" "}
                                Is there parking?
                              </span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <p>Yes, private parking is provided.</p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="2"
                              onClick={() => this.toggle("c")}
                            >
                              <span className="question-header">
                                {this.state.questions["c"] ? (
                                  <FaChevronDown />
                                ) : (
                                  <FaChevronRight />
                                )}{" "}
                                Are dogs allowed?
                              </span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                <p>
                                  1 well behaved dog is welcome at Rhylla
                                  Cottage for a cost of £30.
                                </p>
                                <p>
                                  No dogs are allowed to stay in Rhylla Retreat.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="3"
                              onClick={() => this.toggle("d")}
                            >
                              <span className="question-header">
                                {this.state.questions["d"] ? (
                                  <FaChevronDown />
                                ) : (
                                  <FaChevronRight />
                                )}{" "}
                                Am I able to smoke or vape inside?
                              </span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body>
                                <p>Smoking/vaping is not permitted inside.</p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="4"
                              onClick={() => this.toggle("e")}
                            >
                              <span className="question-header">
                                {this.state.questions["e"] ? (
                                  <FaChevronDown />
                                ) : (
                                  <FaChevronRight />
                                )}{" "}
                                Can I bring a baby?
                              </span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body>
                                <p>Please call to discuss.</p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="5"
                              onClick={() => this.toggle("f")}
                            >
                              <span className="question-header">
                                {this.state.questions["f"] ? (
                                  <FaChevronDown />
                                ) : (
                                  <FaChevronRight />
                                )}{" "}
                                Is linen provided?
                              </span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body>
                                <p>
                                  All bed linen and towels are provided except
                                  for beach towels.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="6"
                              onClick={() => this.toggle("g")}
                            >
                              <span className="question-header">
                                {this.state.questions["g"] ? (
                                  <FaChevronDown />
                                ) : (
                                  <FaChevronRight />
                                )}{" "}
                                Is there wheelchair access?
                              </span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="6">
                              <Card.Body>
                                <p>
                                  Unfortunately due to the age and design of the
                                  cottage wheelchair access would be difficult.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="7"
                              onClick={() => this.toggle("h")}
                            >
                              <span className="question-header">
                                {this.state.questions["h"] ? (
                                  <FaChevronDown />
                                ) : (
                                  <FaChevronRight />
                                )}{" "}
                                Is there WiFi?
                              </span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="7">
                              <Card.Body>
                                <p>
                                  Yes there is WiFi, but the speed is limited
                                  due to the location of the properties.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          {/* Early check-in/early check-out? Pets? Children? Parking? Smoking/vaping? Wheelchair-access? Internet/WiFi? Animals? Accommodate... but please fill in form below. */}
                        </Accordion>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col
                md={{ span: 4, order: 2 }}
                sm={{ order: 1 }}
                xs={{ order: 1 }}
                style={{ marginBottom: 32 }}
              >
                <Card>
                  <Card.Body>
                    <h2>Get in touch!</h2>
                    <p>We'd love to hear from you!</p>
                    <p>
                      Call us on: <a href="tel:01822870806">01822 870806</a>
                    </p>
                    <p>
                      Send an email to:{" "}
                      <a href="mailto:rhylladevon@gmail.com">
                        rhylladevon@gmail.com
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://www.facebook.com/Rhylla-Devon-102076861602837/"
                        style={{ marginRight: 16 }}
                      >
                        <FaFacebook
                          style={{ fill: "#177af2", height: 48, width: 48 }}
                        ></FaFacebook>
                      </a>
                      <a href="https://www.instagram.com/rhylladevon">
                        <span className="instagram">
                          <FaInstagram
                            style={{ fill: "white", height: 36, width: 36 }}
                          ></FaInstagram>
                        </span>
                      </a>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}
