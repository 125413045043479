import React from "react";
import { Button, Form, Alert } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            status: "",
            clickedSubmit: false,
            name: '',
            email: '',
            message: '',
            nameValid: false,
            emailValid: false,
            messageValid: false
        };

        this.validateName = this.validateName.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateMessage = this.validateMessage.bind(this);

        this.updateName = this.updateName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    resetState(){
        this.setState({status: ''});
    }
  
    
    render() {

        if(this.state.status === "SUCCESS"){
            return (
                <Alert variant="success">
                    <Alert.Heading>Thanks!</Alert.Heading>
                    <p>We'll get back to you soon.</p>
                </Alert>
            );
       
        } else if(this.state.status === "ERROR"){
            return (
                <Alert variant="danger">
                    <Alert.Heading>Ooops!</Alert.Heading>
                    <p>Something went wrong whilst sending the form!</p>
                    <a onClick={this.resetState}>Try again</a>
                </Alert>
            )
        } else {

            return(
                <Form onSubmit={this.submitForm} action="https://formspree.io/xnqgngww" method="POST">
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control value={this.state.name} onChange={this.updateName} isInvalid={!this.state.nameValid && this.state.clickedSubmit} name='name' type="text" placeholder="Enter your name" />
                        <Form.Control.Feedback type="invalid">Please include your name.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control value={this.state.email} onChange={this.updateEmail} isInvalid={!this.state.emailValid && this.state.clickedSubmit} name='email' type="email" placeholder="Enter your email address" />
                        <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control value={this.state.message} onChange={this.updateMessage} isInvalid={!this.state.messageValid && this.state.clickedSubmit} name='message' as="textarea" rows="3" placeholder="Enter your message"/>
                        <Form.Control.Feedback type="invalid">
                      Please include a message.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Control type="text" name="_gotcha" style={{display: 'none' }} />
                    <ReCAPTCHA
                        size='compact'
                        sitekey="6LcM9AAVAAAAABqVvNYJntq2AR1L7jG_2bFyFVnr"
                    />
                    <br/>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form>
        
            ) 
        }
    }

    validateName(name){
        if(name){
            return name.length > 0 ? true : false;
        } else {
            return false;
        }
    }

    validateEmail(email){  
        if(email){
            return email.length > 0 && email.includes('@') ? true : false;
        } else {
            return false;
        }
        
    }

    validateMessage(message){
        if(message){
            return message.length > 0 ? true : false;
        } else {
            return false;
        }
        
    }

    updateName(data){
        let name = data.target.value;
        let isValid = this.validateName(name);
        this.setState({name, nameValid: isValid});
    }

    updateEmail(data){
        let email = data.target.value;
        let isValid = this.validateEmail(email);
        this.setState({email, emailValid: isValid});
    }

    updateMessage(data){
        let message = data.target.value;
        let isValid = this.validateMessage(message);
        this.setState({message, messageValid: isValid});
    }


    submitForm(ev) {
        const form = ev.currentTarget;

        ev.preventDefault();

        if(!this.state.nameValid || !this.state.emailValid || !this.state.messageValid){
            
            ev.stopPropagation();
            this.setState({clickedSubmit: true})
        } else {
            const data = new FormData(form);
            const xhr = new XMLHttpRequest();
            xhr.open(form.method, form.action);
            xhr.setRequestHeader("Accept", "application/json");
            xhr.onreadystatechange = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) return;
                if (xhr.status === 200) {
                    form.reset();
                    this.setState({ status: "SUCCESS" });
                } else {
                    this.setState({ status: "ERROR" });
                }
            };
            
            xhr.send(data); 
        }
    
    
    }
}